@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic",
    YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
}
@media all and (-ms-high-contrast: none) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}
@media all and (-ms-high-contrast: active) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

body {
  padding: 0px 0px;
}

h1 {
    font-size: 1.7em;
    margin-block-end: 0px;
}

h2 {
    font-size: 1.5em;
}

footer {
    color: #777;
    font-size: 80%;
    margin-block-start: 2em;
    margin-block-end: 1em;
}

footer a {
  text-decoration: underline #999;
  cursor: pointer;
  color: inherit;
  text-underline-offset: 2px;
}

.source-description a:hover {
  text-decoration: underline #aaa;
}

.source-description ruby > rt {
  font-size: 5%;
  color: #999;
}

table.sidebar {
    max-width: 10em;
}

.mw-parser-output .sidebar-title, .mw-parser-output .sidebar-title-with-pretitle {
    font-size: 110% !important;
}

table.sidebar td, table.sidebar th {
    font-size: 85%;
    color: #444;
}

table.sidebar ul, table.sidebar ol {
    padding-inline-start: 0px;
}

table.sidebar td li, table.sidebar tr li {
    text-align: left;
    display: inline-block;
}

article {
  padding: 0px 20px 20px 20px;
  width: 620px;
  margin-right: auto;
  margin-left: auto;
  color: #222;
  line-height: 2em;
}

@media only screen and (max-width: 768px) {
  article {
    width: auto;
    max-width: 100%;
    font-size: 1.2em;
    line-height: 2.2em;
  }
}

article a {
    text-decoration: underline #666;
    cursor: pointer;
    color: inherit;
    text-underline-offset: 2px;
    word-wrap: break-word;
}

article a:hover {
    text-decoration: underline #888;
}

p {
  line-height: 2em;
}

ruby > rt {
  font-size: 10%;
  color: #666;
  top: 0.6ex;
  user-select: none;
}

.mw-editsection {
    display: none;
}

.scrollable-container, .noresize {
  overflow: auto;
}

.wikitable th, .wikitable td {
  white-space: nowrap;
}

img {
  max-width: 100%;
}

table.mbox-small {
  display: none;
}
